<template>
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer width="300" color="stv-gree-gradient pl-5 pr-5">
      <v-list v-if="filteredListFeatures" class="mt-6" nav>
        <v-list-item
          to="/dashboard"
          value="dashboard"
          class="stv-item-list-menu mb-6 stv-logo-active-menu"
          active-class="stv-logo-active-menu"
        >
          <img src="/img/stv-logo.svg" />
        </v-list-item>

        <v-list-item
          v-for="item in filteredListFeatures"
          :key="item.title"
          :prepend-icon="item.icon"
          :title="item.title"
          :value="item.value"
          :to="item.to"
          class="stv-item-list-menu mb-6"
          active-class="stv-active-menu"
        ></v-list-item>
        <v-divider color="#5c6d32" class="mb-6"></v-divider>

        <v-list-item
          v-for="item in filteredListRegister"
          :key="item.title"
          :prepend-icon="item.icon"
          :title="item.title"
          :value="item.value"
          :to="item.to"
          class="stv-item-list-menu mb-6"
          active-class="stv-active-menu"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="transparent" flat style="position: absolute">
      <template #append>
        <NotificationNav v-if="authenticatedUser.customer"></NotificationNav>
        <BaseAvatar></BaseAvatar>
        <span
          v-if="authenticatedUser.customer"
          class="font-weight-medium ml-1"
          >{{ authenticatedUser.customer.fantasyName }}</span
        >
      </template>
      <template #title>
        <div>
          <span v-show="breadcrumbs.length == 0" class="text-stv-green-darken"
            >{{ saudacao }} ✋</span
          >
          <v-breadcrumbs v-show="breadcrumbs.length > 0" :items="breadcrumbs">
            <template #divider>
              <v-icon color="#8d8d8d" icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </template>
    </v-app-bar>

    <v-main>
      <PerfectScrollbar>
        <div class="pa-4 v-main-div">
          <div
            class="d-flex align-items-center"
            style="justify-content: space-between"
          >
            <h1>{{ title }}</h1>
            <div>
              <ButtonsBaseCreateButton
                v-if="route.meta.pathCreate"
                :to="route.meta.pathCreate"
                :title="route.meta.titleCreate"
              />
            </div>
          </div>
          <NuxtPage />
        </div>
      </PerfectScrollbar>
    </v-main>
  </v-layout>
</template>

<script setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { storeToRefs } from "pinia";
import { useAuthUserStore } from "~/store/users";

const store = useAuthUserStore();
const { authenticatedUser } = storeToRefs(store);

const title = ref("");
const breadcrumbs = ref([]);

const route = useRoute();
onMounted(() => {
  title.value = route.meta.title;
  if (route.meta.breadcrumbs) {
    breadcrumbs.value = route.meta.breadcrumbs;
  } else {
    breadcrumbs.value = [];
  }
});
watch(route, (newRoute) => {
  title.value = newRoute.meta.title;
  if (newRoute.meta.breadcrumbs) {
    breadcrumbs.value = newRoute.meta.breadcrumbs;
  } else {
    breadcrumbs.value = [];
  }
});

const listRegister = ref([
  {
    title: "Assinantes",
    icon: "mdi-television",
    to: "/assinantes",
    value: "subscribers",
    roles: ["franchisee"],
  },
  {
    title: "Conteudos",
    icon: "mdi-movie-play",
    to: "/conteudos",
    value: "contents",
    roles: ["admin"],
  },
  {
    title: "Financeiro",
    icon: "mdi-cash",
    to: "/financeiro",
    value: "contratos.view",
    roles: ["franchisee"],
  },
  {
    title: "Anunciantes",
    icon: "mdi-publish",
    to: "/anunciantes",
    value: "publishers",
    roles: ["franchisee"],
  },
  {
    title: "Franqueados",
    icon: "mdi-account-multiple-plus-outline",
    to: "/franqueados",
    value: "franqueados",
    roles: ["admin"],
  },
  {
    title: "Tecnicos",
    icon: "mdi-account-multiple-plus-outline",
    to: "/tecnicos",
    value: "tecnicos",
    roles: ["admin"],
  },
]);

const listFeatures = ref([
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard-outline",
    to: "/dashboard",
    value: "dashboard",
    roles: ["franchisee", "admin"],
  },
  {
    title: "Relatório",
    icon: "mdi-chart-bar",
    to: "/relatorio",
    value: "relatorio",
    roles: ["franchisee", "admin"],
  },
  {
    title: "Status",
    icon: "mdi-list-status",
    to: "/status",
    value: "status",
    roles: ["franchisee", "admin", "technician"],
  },
]);

const filteredListFeatures = computed(() => {
  return listFeatures.value.filter((item) => {
    return item.roles.includes(authenticatedUser.value.type);
  });
});

const filteredListRegister = computed(() => {
  return listRegister.value.filter((item) => {
    // aqui estou assumindo que todos os items em listRegister têm um array de "roles"
    return item.roles.includes(authenticatedUser.value.type);
  });
});

const saudacao = computed(() => {
  const hora = new Date().getHours();

  return hora >= 5 && hora < 12
    ? "Olá, Bom dia!"
    : hora >= 12 && hora < 18
    ? "Olá, Boa tarde!"
    : "Olá, Boa noite!";
});
</script>

<style lang="scss">
.v-breadcrumbs-item {
  color: #8d8d8d;
  opacity: 1;
  &.v-breadcrumbs-item--disabled {
    color: #5c6d32;
    opacity: 1;
  }
}

.v-main-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 90vh;
  overflow: auto;
}

.stv-title {
  font-size: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--escura, #333);
}
nav {
  background: linear-gradient(
      180deg,
      rgba(242, 249, 226, 0.5) 0%,
      rgba(240, 249, 222, 0.5) 100%
    ),
    #fff !important;
}
.v-navigation-drawercontent {
  background: transparent !important;
}
.stv-logo-active-menu {
  background-color: transparent !important;
  .v-list-item__overlay {
    background-color: transparent !important;
    color: transparent !important;
  }
}
.stv-item-list-menu {
  &.v-list-item--active {
    .v-list-itemoverlay {
      background-color: #5c6d32 !important;
      opacity: 1 !important;
    }
    .v-list-itemcontent {
      z-index: 99;
      .v-list-item-title {
        color: #fff !important;
      }
    }
    .v-list-itemprepend {
      color: #fff;
    }
  }
  .v-list-item-title {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--verde-escuro, #5c6d32);
  }
  .v-list-item__prepend {
    color: var(--verde-escuro, #5c6d32);
    i {
      margin-inline-end: 16px;
    }
  }
  &.stv-active-menu {
    background-color: #5c6d32;
    i {
      color: white;
    }
    .v-list-item-title {
      color: white;
    }
  }
}
</style>
