<template>
  <v-menu max-width="600px">
    <template #activator="{ props }">
      <v-btn icon v-bind="props" class="text-none custom-button">
        <template v-if="contractsWithLateInstallments.length > 0">
          <v-badge
            :content="contractsWithLateInstallments.length"
            color="error"
          >
            <v-icon color="#5c6d32">mdi-bell-outline</v-icon>
          </v-badge>
        </template>
        <template v-else>
          <v-icon color="#5c6d32">mdi-bell-outline</v-icon>
        </template>
      </v-btn>
    </template>
    <v-card
      class="card-rounded mx-auto"
      color="#FAFFF5"
      elevation="1"
      style="border: 2px solid #ffffff"
    >
      <v-card-text class="pa-1">
        <v-list density="compact" bg-color="#FAFFF5">
          <v-list-subheader>
            <h3 class="font-weight-bold">Notificações</h3>
            <template v-if="contractsWithLateInstallments.length === 0">
              <p>Não há notificações</p>
            </template>
          </v-list-subheader>

          <v-list-item
            v-for="(item, i) in contractsWithLateInstallments"
            :key="i"
            :value="item.name"
            :base-color="getColor(item.type)"
            prepend-icon="mdi-calendar-clock"
          >
            <v-list-item-title>
              {{ getTitle(item.type) }} <b> {{ item.name }} </b>
            </v-list-item-title>

            <template #prepend>
              <v-avatar
                :color="getColor(item.type) + 33"
                rounded="0"
                size="36px"
              >
                <v-icon :icon="item.icon" :color="getColor(item.type)">
                </v-icon>
              </v-avatar>
            </template>
          </v-list-item>
        </v-list>
        <div
          v-for="item in contractsWithLateInstallments"
          :key="item.name"
          class="mx-auto"
        >
          <!-- <div v-if="item.type === 'dueIn5Days'">
            <v-divider :key="item.name + '-divider'" class="my-3"></v-divider>
            <v-btn
              class="stv-button-menu text-none"
              rounded
              variant="text"
              prepend-icon="mdi-calendar-clock"
              :class="{ active: item.isActive }"
              color="#5c6d32"
            >
              <template v-slot:prepend>
                <v-icon color="success"></v-icon>
              </template>
              Faltam 5 dias para o pagamento do parceiro {{ item.name }}
            </v-btn>
          </div>

          <div v-if="item.type === 'dueTomorrow'">
            <v-divider :key="item.name + '-divider2'" class="my-3"></v-divider>
            <v-btn
              class="stv-button-menu text-none"
              rounded
              variant="text"
              prepend-icon="mdi-calendar-clock"
              :class="{ active: item.isActive }"
              color="#5c6d32"
            >
              <template v-slot:prepend>
                <v-icon color="success"></v-icon>
              </template>
              Falta 1 dia para o pagamento do parceiro {{ item.name }}
            </v-btn>
          </div>
          <div v-if="item.type === 'dueToday'">
            <v-divider :key="item.name + '-divider3'" class="my-3"></v-divider>
            <v-btn
              class="stv-button-menu text-none"
              rounded
              variant="text"
              prepend-icon="mdi-calendar-alert"
              :class="{ active: item.isActive }"
              color="#F9A825"
            >
              <template v-slot:prepend>
                <div></div>
                <v-icon color="warning"></v-icon>
              </template>
              O pagamento do parceiro {{ item.name }} é hoje
            </v-btn>
          </div>
          <div v-if="item.type === 'late'">
            <v-divider :key="item.name + '-divider4'" class="my-3"></v-divider>
            <v-btn
              class="stv-button-menu text-none"
              rounded
              variant="text"
              prepend-icon="mdi-calendar-remove"
              :class="{ active: item.isActive }"
              color="#F44336"
            >
              Pagamento atrasado do parceiro {{ item.name }}
            </v-btn>
          </div> -->
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script setup>
import { useAuthUserStore } from "../store/users";
const store = useAuthUserStore();
const authUser = store.authenticatedUser;
const contractsWithLateInstallments = ref([]);

const advertisers = ref([{ name: "academia", isActive: false }]);
const activeButtonsCount = ref(0);

const getColor = (type) => {
  switch (type) {
    case "dueIn5Days":
      return "#5c6d32";
    case "dueTomorrow":
      return "#5c6d32";
    case "dueToday":
      return "#F9A825";
    case "late":
      return "#F44336";
  }
};

const getTitle = (type) => {
  switch (type) {
    case "dueIn5Days":
      return "Faltam 5 dias para o pagamento do parceiro";
    case "dueTomorrow":
      return "Falta 1 dia para o pagamento do parceiro";
    case "dueToday":
      return "O pagamento do parceiro é hoje";
    case "late":
      return "Pagamento atrasado do parceiro";
  }
};

onMounted(() => {
  const currentDate = new Date();
  for (const contract of authUser.customer.contracts) {
    for (const installment of contract.payment.installments) {
      const dueDateObj = new Date(installment.dueDate);
      const currentDateStripped = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      const dueDateStripped = new Date(
        dueDateObj.getFullYear(),
        dueDateObj.getMonth(),
        dueDateObj.getDate()
      );
      const timeDifference = dueDateStripped - currentDateStripped; // Diferença em milissegundos
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Converter para dias
      if (!installment.paid) {
        if (dueDateObj < currentDate && daysDifference !== 0) {
          contractsWithLateInstallments.value.push({
            name: contract.company?.fantasyName,
            dueDate: installment.dueDate,
            installment: installment.installment,
            isActive: true,
            type: "late",
          });
          break;
        } else if (daysDifference === 0) {
          // Vencimento hoje
          contractsWithLateInstallments.value.push({
            name: contract.company?.fantasyName,
            dueDate: installment.dueDate,
            installment: installment.installment,
            isActive: true,
            type: "dueToday",
          });
          break;
        } else if (daysDifference === 1) {
          // 1 dia faltando para o vencimento
          contractsWithLateInstallments.value.push({
            name: contract.company?.fantasyName,
            dueDate: installment.dueDate,
            installment: installment.installment,
            isActive: true,
            type: "dueTomorrow",
          });
          break;
        } else if (daysDifference === 5) {
          // 5 dias faltando para o vencimento
          contractsWithLateInstallments.value.push({
            name: contract.company?.fantasyName,
            dueDate: installment.dueDate,
            installment: installment.installment,
            isActive: true,
            type: "dueIn5Days",
          });
          break;
        }
      }
    }
  }
});

// const fetchAPI = async () => {
//   try {
//     const response = await fetch("URL_DA_API");
//     const data = await response.json();

//     for (const advertiser of advertisers.value) {
//       const isActive = data.some(
//         (item) => item.name === advertiser.name && item.isActive
//       );
//       advertiser.isActive = isActive;
//     }

//     activeButtonsCount.value = data.filter((item) => item.isActive).length;
//   } catch (error) {
//     console.error("Erro ao buscar dados da API:", error);
//   }
// };

// onMounted(fetchAPI); // Chama a API ao inicializar o componente
</script>
<style lang="scss">
.stv-button-menu {
  padding: 0 !important;
}

.card-rounded {
  border-radius: 8px;
  .v-list-item-title {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
  }
  .v-avatar {
    color: #e2edc8;
  }
}

.custom-button .v-btn--active > .v-btn__overlay,
.v-btn[aria-haspopup="menu"][aria-expanded="true"] > .v-btn__overlay {
  opacity: 0.05 !important;
}
</style>
