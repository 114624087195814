<template>
  <v-menu min-width="200px" rounded>
    <template #activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar color="#f2f9e280" size="small">
          <span v-if="!authenticatedUser?.customer?.logo" class="text-avatar">{{
            userInitials
          }}</span>
          <v-img v-else :src="authenticatedUser?.customer?.logo"></v-img>
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="rounded-xl">
      <v-card-text class="pa-6 m-0">
        <div class="mx-auto">
          <h3 class="font-weight-bold">Conta</h3>
          <v-divider class="my-3"></v-divider>
          <NuxtLink
            :to="`/meu-perfil/${authenticatedUser?._id}`"
            style="text-decoration: none; color: #000000"
          >
            <v-btn
              class="stv-button-menu d-flex text-start justify-start w-100 h-100"
              style="margin: 0px"
              variant="text"
              prepend-icon="mdi-account-outline"
            >
              Meu Perfil
            </v-btn>
          </NuxtLink>

          <v-divider class="my-3"></v-divider>
          <v-btn
            class="stv-button-menu d-flex text-start justify-start"
            variant="text"
            prepend-icon="mdi-logout"
            @click="logout"
          >
            Sair da conta
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useAuthUserStore } from "~/store/users";
const store = useAuthUserStore();
const { authenticatedUser } = storeToRefs(store);

const userInitials = computed(() => {
  if (authenticatedUser.value?.customer) {
    const nomeCompleto = authenticatedUser.value.customer.fantasyName;
    console.log(authenticatedUser.value);
    const nomes = nomeCompleto.split(" ");
    const inicialPrimeiroNome = nomes[0].charAt(0);
    let inicialSegundoNome = "";
    if (nomes.length > 1) {
      inicialSegundoNome = nomes[1].charAt(0);
    }
    return inicialPrimeiroNome + inicialSegundoNome;
  }
});

const logout = () => {
  const authCookie = useCookie("token");
  authCookie.value = null;
  navigateTo("/login");
};
</script>

<style scoped lang="scss">
.stv-button-menu {
  padding: 0 !important;
}

::v-slotted(.stv-button-menu) .v-btn__prepend {
  padding: 8px;
  border-radius: 4px;
  background: var(--verde-claro, #e2edc8);
  margin: 0;
}

.v-avatar {
  border: 2px solid #5c6d32;
  &.text-avatar {
    padding: 10px;
  }
}

.card-rounded {
  border-radius: 15px !important;
}
</style>
